.error-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.header-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
